const investment = {
  investmentsToArm: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_from_EAEU/',
    params: { region: 'Армения' },
  },
  investmentsToBel: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_from_EAEU/',
    params: { region: 'Беларусь' },
  },
  investmentsToKaz: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_from_EAEU/',
    params: { region: 'Казахстан' },
  },
  investmentsToKyr: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_from_EAEU/',
    params: { region: 'Кыргызстан' },
  },
  investmentsToRus: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_from_EAEU/',
    params: { region: 'Россия' },
  },
  accruedMutualFDIVolumeByYearEAEU: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_EAEU/',
  },
  accruedMutualFDIVolumeByYearArm: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_from_EAEU/',
    params: { region: 'Армения' },
  },
  accruedMutualFDIVolumeByYearBel: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_from_EAEU/',
    params: { region: 'Беларусь' },
  },
  accruedMutualFDIVolumeByYearKaz: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_from_EAEU/',
    params: { region: 'Казахстан' },
  },
  accruedMutualFDIVolumeByYearRus: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_from_EAEU/',
    params: { region: 'Россия' },
  },

  structureInvestments: {
    method: 'GET',
    url: 'investment/volume_mutual_EAEU_by_previous_years/',
  },
  investmentsToCountry: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_to_EAEU/',
  },
  investmentsToEAEU: {
    method: 'GET',
    url: 'EAEU/investment/yearly_FDI_flows_EAEU/',
  },
  investmentsFromRusToAzeLast: {
    method: 'GET',
    url: 'Azerbaijan/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromAzeLast: {
    method: 'GET',
    url: 'Azerbaijan/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToAzePrev: {
    method: 'GET',
    url: 'Azerbaijan/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromAzePrev: {
    method: 'GET',
    url: 'Azerbaijan/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  investmentsStructureInLast: {
    method: 'GET',
    url: 'Azerbaijan/investment/actual_top10/',
    params: { variable: 'in' },
  },
  investmentsStructureOutLast: {
    method: 'GET',
    url: 'Azerbaijan/investment/actual_top10/',
    params: { variable: 'out' },
  },

  investmentsFromRusToTajLast: {
    method: 'GET',
    url: 'Tajikistan/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromTajLast: {
    method: 'GET',
    url: 'Tajikistan/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToTajPrev: {
    method: 'GET',
    url: 'Tajikistan/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromTajPrev: {
    method: 'GET',
    url: 'Tajikistan/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },

  investmentsFromRusToGeoLast: {
    method: 'GET',
    url: 'Georgia/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromGeoLast: {
    method: 'GET',
    url: 'Georgia/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToGeoPrev: {
    method: 'GET',
    url: 'Georgia/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromGeoPrev: {
    method: 'GET',
    url: 'Georgia/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  investmentsFromRusToUzbLast: {
    method: 'GET',
    url: 'Uzbekistan/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromUzbLast: {
    method: 'GET',
    url: 'Uzbekistan/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToUzbPrev: {
    method: 'GET',
    url: 'Uzbekistan/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromUzbPrev: {
    method: 'GET',
    url: 'Uzbekistan/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  investmentsFromRusToUkrLast: {
    method: 'GET',
    url: 'Ukraine/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromUkrLast: {
    method: 'GET',
    url: 'Ukraine/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToUkrPrev: {
    method: 'GET',
    url: 'Ukraine/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromUkrPrev: {
    method: 'GET',
    url: 'Ukraine/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  investmentsFromRusToMolLast: {
    method: 'GET',
    url: 'Moldova/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromMolLast: {
    method: 'GET',
    url: 'Moldova/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToMolPrev: {
    method: 'GET',
    url: 'Moldova/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromMolPrev: {
    method: 'GET',
    url: 'Moldova/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  investmentsFromRusToTurLast: {
    method: 'GET',
    url: 'Turkmenistan/investment/quarterly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromTurLast: {
    method: 'GET',
    url: 'Turkmenistan/investment/quarterly_with_ru/',
    params: { variable: 'to' },
  },
  investmentsFromRusToTurPrev: {
    method: 'GET',
    url: 'Turkmenistan/investment/yearly_with_ru/',
    params: { variable: 'from' },
  },
  investmentsToRusFromTurPrev: {
    method: 'GET',
    url: 'Turkmenistan/investment/yearly_with_ru/',
    params: { variable: 'to' },
  },

  InvestmentsSocEconomAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_ru_investment/',
    params: { type: 'soc_econom' },
  },

  InvestmentsInvestProgAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_ru_investment/',
    params: { type: 'invest_prog' },
  },

  YearlySalaryAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_salary/',
  },

  YearlyRuInvestmentSO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/investment/yearly_dynamics_with_ru/ ',
  },
  YearlySalarySO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/macro/yearly_salary/',
  },
};

export default investment;
