const unionState = {
  GDPByQuarterUSBel: {
    method: 'GET',
    url: 'Union_State/macro/gdp_quarterly/',
    params: { type: 'Беларусь' },
  },
  GDPByQuarterUSRus: {
    method: 'GET',
    url: 'Union_State/macro/gdp_quarterly/',
    params: { type: 'Россия' },
  },

  GDPByYearUSBel: {
    method: 'GET',
    url: 'Union_State/macro/gdp_yearly/',
    params: { type: 'Беларусь' },
  },
  GDPByYearUSRus: {
    method: 'GET',
    url: 'Union_State/macro/gdp_yearly/',
    params: { type: 'Россия' },
  },

  AgricultureQuarterlyBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_agriculture/',
    params: { region: 'Беларусь' },
  },
  AgricultureQuarterlyRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_agriculture/',
    params: { region: 'Россия' },
  },

  AgricultureYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_agriculture/',
    params: { region: 'Беларусь' },
  },
  AgricultureYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_agriculture/',
    params: { region: 'Россия' },
  },

  IndustrialByQuarterUSBel: {
    method: 'GET',
    url: 'Union_State/macro/industrial_quarterly/',
    params: { type: 'Беларусь' },
  },
  IndustrialByQuarterUSRus: {
    method: 'GET',
    url: 'Union_State/macro/industrial_quarterly/',
    params: { type: 'Россия' },
  },
  IndustrialByYearUSBel: {
    method: 'GET',
    url: 'Union_State/macro/industrial_yearly/',
    params: { type: 'Беларусь' },
  },
  IndustrialByYearUSRus: {
    method: 'GET',
    url: 'Union_State/macro/industrial_yearly/',
    params: { type: 'Россия' },
  },
  EconomicEffectsUS: {
    method: 'GET',
    url: 'hardcode/Union_State/union_programs/economic_effects/',
  },
  QualityAssessmentUS: {
    method: 'GET',
    url: 'hardcode/Union_State/union_programs/quality_assessment/',
  },

  CommoditiesTradeImport: {
    method: 'GET',
    url: 'Union_State/commodities_trade/monthly_belstat_with_ru/',
    params: { type: 'import' },
  },
  CommoditiesTradeExport: {
    method: 'GET',
    url: 'Union_State/commodities_trade/monthly_belstat_with_ru/',
    params: { type: 'export' },
  },
  CommoditiesTradeTurnover: {
    method: 'GET',
    url: 'Union_State/commodities_trade/monthly_belstat_with_ru/',
    params: { type: 'turnover' },
  },

  ServicesTradeImport: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_blr/',
    params: { variable: 'import' },
  },
  ServicesTradeExport: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_blr/',
    params: { variable: 'export' },
  },
  ServicesTradeTurnover: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_blr/',
    params: { variable: 'turnover' },
  },

  ServicesTradeImportWorldRus: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_with_world/',
    params: { variable: 'import' },
  },
  ServicesTradeExportWorldRus: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_with_world/',
    params: { variable: 'export' },
  },
  ServicesTradeTurnoverWorldRus: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_rus_with_world/',
    params: { variable: 'turnover' },
  },

  ServicesTradeImportWorldBel: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_blr_with_world/',
    params: { variable: 'import' },
  },
  ServicesTradeExportWorldBel: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_blr_with_world/',
    params: { variable: 'export' },
  },
  ServicesTradeTurnoverWorldBel: {
    method: 'GET',
    url: 'Union_State/services_trade/yearly_blr_with_world/',
    params: { variable: 'turnover' },
  },

  ServicesTradeTopFiveExportRus: {
    method: 'GET',
    url: 'Union_State/services_trade/top5_rus_last_year/',
    params: { variable: 'export' },
  },

  ServicesTradeTopFiveImportRus: {
    method: 'GET',
    url: 'Union_State/services_trade/top5_rus_last_year/',
    params: { variable: 'import' },
  },
  ServicesTradeTopFiveExportBel: {
    method: 'GET',
    url: 'Union_State/services_trade/top5_blr_last_year/',
    params: { variable: 'export' },
  },
  ServicesTradeTopFiveImportBel: {
    method: 'GET',
    url: 'Union_State/services_trade/top5_blr_last_year/',
    params: { variable: 'import' },
  },

  CommoditiesTradeYearlyImport: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_rus_blr/',
    params: { type: 'import' },
  },
  CommoditiesTradeYearlyExport: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_rus_blr/',
    params: { type: 'export' },
  },
  CommoditiesTradeYearlyTurnover: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_rus_blr/',
    params: { type: 'turnover' },
  },

  CommoditiesУearlyСomplementarityRusBel: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_complementarity_rus_blr/',
  },

  CommoditiesTradeYearlyGroupsExportVolume: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_groups_rus_blr/',
    params: { type: 'export', measure: 'share' },
  },

  CommoditiesTradeYearlyGroupsExportShare: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_groups_rus_blr/',
    params: { type: 'export', measure: 'volume' },
  },

  CommoditiesTradeYearlyGroupsImportVolume: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_groups_rus_blr/',
    params: { type: 'import', measure: 'share' },
  },

  CommoditiesTradeYearlyGroupsImportShare: {
    method: 'GET',
    url: 'Union_State/commodities_trade/yearly_groups_rus_blr/',
    params: { type: 'import', measure: 'volume' },
  },

  ServicesTradeYearlyGroupsExportVolume: {
    method: 'GET',
    url: 'Union_State/services_trade/struct_rus_blr_last_year/',
    params: { type: 'export', measure: 'share' },
  },

  ServicesTradeYearlyGroupsExportShare: {
    method: 'GET',
    url: 'Union_State/services_trade/struct_rus_blr_last_year/',
    params: { type: 'export', measure: 'volume' },
  },

  ServicesTradeYearlyGroupsImportVolume: {
    method: 'GET',
    url: 'Union_State/services_trade/struct_rus_blr_last_year/',
    params: { type: 'import', measure: 'share' },
  },

  ServicesTradeYearlyGroupsImportShare: {
    method: 'GET',
    url: 'Union_State/services_trade/struct_rus_blr_last_year/',
    params: { type: 'import', measure: 'volume' },
  },

  Top5PartnersExportBel: {
    method: 'GET',
    url: 'Union_State/commodities_trade/top5_partners/',
    params: { region: 'Беларусь', variable: 'export' },
  },

  Top5PartnersExportRus: {
    method: 'GET',
    url: 'Union_State/commodities_trade/top5_partners/',
    params: { region: 'Россия', variable: 'export' },
  },

  Top5PartnersImportBel: {
    method: 'GET',
    url: 'Union_State/commodities_trade/top5_partners/',
    params: { region: 'Беларусь', variable: 'import' },
  },

  Top5PartnersImportRus: {
    method: 'GET',
    url: 'Union_State/commodities_trade/top5_partners/',
    params: { region: 'Россия', variable: 'import' },
  },

  InvestmentsYearlyBelstatIn: {
    method: 'GET',
    url: 'Union_State/investments/yearly_belstat_investments/',
    params: { type: 'in' },
  },
  InvestmentsYearlyBelstatOut: {
    method: 'GET',
    url: 'Union_State/investments/yearly_belstat_investments/',
    params: { type: 'out' },
  },

  InvestmentsYearlyCbrFrom: {
    method: 'GET',
    url: 'Union_State/investments/yearly_cbr_investments/',
    params: { type: 'from' },
  },
  InvestmentsYearlyCbrTo: {
    method: 'GET',
    url: 'Union_State/investments/yearly_cbr_investments/',
    params: { type: 'to' },
  },
  InvestmentsYearlyCbrWithBelFrom: {
    method: 'GET',
    url: 'Union_State/investments/yearly_cbr_with_bel/',
    params: { type: 'from' },
  },
  InvestmentsYearlyCbrWithBelTo: {
    method: 'GET',
    url: 'Union_State/investments/yearly_cbr_with_bel/',
    params: { type: 'to' },
  },

  ExportPotentialCIS: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'СНГ' },
  },
  ExportPotentialEAEU: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'ЕАЭС' },
  },
  ExportPotentialAze: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Азербайджан' },
  },
  ExportPotentialArm: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Армения' },
  },
  ExportPotentialBel: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Беларусь' },
  },
  ExportPotentialTaj: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Таджикистан' },
  },
  ExportPotentialKaz: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Казахстан' },
  },
  ExportPotentialKyr: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Киргизия' },
  },
  ExportPotentialMol: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Молдова' },
  },
  ExportPotentialTur: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Туркменистан' },
  },
  ExportPotentialUzb: {
    method: 'GET',
    url: 'hardcode/Russia/export_potential/actual_trade_groups/',
    params: { type: 'Узбекистан' },
  },
};

export default unionState;
